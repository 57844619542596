import React, { createContext, useContext, useState, FC, ReactElement } from 'react';
import { Settings } from 'types';

export type SettingsReturnType = {
    settings: Settings | undefined;
    setSettings: (settings: Settings) => void;
};

const SettingsContext = createContext<SettingsReturnType>({
    settings: undefined,
    setSettings: () => null,
});
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSettings = () => useContext(SettingsContext);

type SettingsProviderProps = {
    children: ReactElement | ReactElement[];
};

const SettingsProvider: FC<SettingsProviderProps> = ({ children }) => {
    const [settings, setSettings] = useState<Settings>();

    return (
        <SettingsContext.Provider value={{ settings, setSettings }}>
            {children}
        </SettingsContext.Provider>
    );
};

export { SettingsProvider, useSettings };
