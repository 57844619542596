/* eslint-disable indent */
import React, { FC } from 'react';
import { SurveyData } from 'types';
import styles from './swimlaneItemAppointmentDataRow.module.scss';
import { useTranslation } from 'react-i18next';
import CopyIcon from '../../assets/copy.svg';
import CopyIconDark from '../../assets/copy_dark.svg';

type SwimlaneItemAppointmentDataRowProps = {
    survey?: SurveyData;
    currentAppointmentStatus: string;
    handleCopyPopup?: () => void;
};

const SwimlaneItemAppointmentDataRow: FC<SwimlaneItemAppointmentDataRowProps> = ({
    survey,
    currentAppointmentStatus,
    handleCopyPopup,
}) => {
    const { t } = useTranslation();

    const copy = (text: string) => {
        navigator.clipboard.writeText(text);
        if (handleCopyPopup) {
            handleCopyPopup();
        }
    };

    return (
        <div className={styles.swimlaneItemAppointmentDataRow}>
            {survey ? (
                Object.entries(survey).map(
                    ([key, value]) =>
                        key !== 'email' && (
                            <p key={key} onClick={() => copy(value)} className={styles.surveyText}>
                                {`${key}: ${value ? value : 'ej besvarat'}`}
                                <img
                                    src={
                                        currentAppointmentStatus === 'checked in'
                                            ? CopyIconDark
                                            : CopyIcon
                                    }
                                    className={styles.tooltiptext}
                                    alt=""
                                />
                            </p>
                        )
                )
            ) : (
                <p className={styles.swimlaneItemAppointmentDataItem}>{t('not_answered')}</p>
            )}
        </div>
    );
};

export default SwimlaneItemAppointmentDataRow;
