import React, { createContext, useContext, useState, FC, ReactElement } from 'react';
import { Clinic } from 'types';

export type ClinicContextType = {
    selectedClinic: Clinic | undefined;
    setSelectedClinic: (clinic: Clinic) => void;
    clinicsInOrganization: Clinic[] | undefined;
    setClinicsInOrganization: (clinics: Clinic[]) => void;
};

const ClinicContext = createContext<ClinicContextType>({
    selectedClinic: undefined,
    setSelectedClinic: () => null,
    clinicsInOrganization: undefined,
    setClinicsInOrganization: () => null,
});
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useClinic = () => useContext(ClinicContext);

type ClinicProviderProps = {
    children: ReactElement | ReactElement[];
};

const ClinicProvider: FC<ClinicProviderProps> = ({ children }) => {
    const [selectedClinic, setSelectedClinic] = useState<Clinic>();
    const [clinicsInOrganization, setClinicsInOrganization] = useState<Clinic[]>();

    return (
        <ClinicContext.Provider
            value={{
                selectedClinic,
                setSelectedClinic,
                clinicsInOrganization,
                setClinicsInOrganization,
            }}
        >
            {children}
        </ClinicContext.Provider>
    );
};

export { ClinicProvider, useClinic };
