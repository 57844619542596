import { analytics, organization, signInWithEmailPassword } from 'firebase-service';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import styles from './login.module.scss';
import { FirebaseError } from 'firebase/app';
import { useTranslation } from 'react-i18next';
import { logEvent } from 'firebase/analytics';
import { useAuth } from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const Login: FC = () => {
    const { authUser } = useAuth();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [hasLoginErrors, setHasLoginErrors] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        if (authUser) {
            navigate('/');
        }
        return;
    }, [authUser]);

    useEffect(() => {
        setHasLoginErrors(false);
    }, [username, password]);

    const validateEmail = (email: string) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const signIn = async (e: SyntheticEvent) => {
        const userEmail = `${username}@${organization}`;
        e.preventDefault();
        if (!validateEmail(userEmail)) {
            setHasLoginErrors(true);
            return;
        }

        try {
            const userCred = await signInWithEmailPassword(userEmail, password);

            logEvent(analytics, 'login_successful', { user: userCred });

            if (!('Notification' in window)) {
                return;
            } else if (Notification.permission !== 'denied') {
                await Notification.requestPermission().catch((err) => err);
            }
        } catch (error) {
            if (error instanceof FirebaseError) {
                if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
                    setHasLoginErrors(true);
                    return;
                }
                return;
            }
            setHasLoginErrors(true);
        }
    };
    return (
        <div className={styles.wrapper}>
            <form className={styles.form} onSubmit={signIn}>
                <label className={styles.formLabel} htmlFor="username">
                    {t('login_form_username')}
                </label>
                <input
                    id="username"
                    type="text"
                    className={styles.formField}
                    placeholder={t('login_form_username')}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <label className={styles.formLabel} htmlFor="password">
                    {t('login_form_password')}
                </label>
                <input
                    type="password"
                    id="password"
                    className={styles.formField}
                    placeholder={t('login_form_password')}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button
                    className={styles.submitButton}
                    type="submit"
                    disabled={!username || !password}
                >
                    {t('login_button')}
                </button>
                <div className={styles.error}>{hasLoginErrors && t('login_error')}</div>
            </form>
        </div>
    );
};

export default Login;
