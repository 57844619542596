/* eslint-disable no-console */
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './swimlaneItem.module.scss';
import moment from 'moment';
import 'moment/locale/sv';
import { useRemoveModal } from 'hooks/RemoveModalContext';
import { Appointment } from 'types';
import { collection, doc, getFirestore, Timestamp, updateDoc } from 'firebase/firestore';
import { decrypt } from '../../crypto';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'firebase-service';
import { useSettings } from 'hooks/SettingsContext';
import { useAuth } from 'hooks/useAuth';
import SwimlaneItemAppointmentDataRow from 'components/SwimlaneItemAppointmentDataRow';
import CopyIcon from '../../assets/copy.svg';
import CopyIconDark from '../../assets/copy_dark.svg';

const CROSS_ICON_WHITE = '/assets/cross_icon_white.svg';
const CROSS_ICON_DARK = '/assets/cross_icon_dark.svg';

type SwimlaneItemProps = {
    handleCopyPopup?: () => void;
    appointment: Appointment;
};

const SwimlaneItem: FC<SwimlaneItemProps> = ({ appointment, handleCopyPopup }) => {
    const [currentAppointmentStatus, setCurrentAppointmentStatus] = useState(appointment.status);
    const { settings } = useSettings();
    const { authUser, clinicId } = useAuth();
    const [timeSinceUpdated, setTimeSinceUpdate] = useState(() => {
        return moment(appointment.updatedAt.toDate()).fromNow();
    });
    const { setIsRemoveModalOpen, setAppointmentsToClose } = useRemoveModal();
    useEffect(() => {
        const interval = setInterval(() => {
            if (!appointment.updatedAt) return;
            setTimeSinceUpdate(moment(appointment.updatedAt.toDate()).fromNow());
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { t } = useTranslation();
    const formatSecurityNumber = (ciphertext: string): string => {
        const securityNumber = ciphertext && decrypt(ciphertext);
        return [securityNumber?.slice(0, 6), '-', securityNumber?.slice(6, 10)].join('');
    };

    const getAppointmentStatusStyles = (appointmentStatus: string) => {
        switch (appointmentStatus) {
            case 'in treatment':
                return styles.swimlaneItemBgLightBlueToGreen;
            case 'treated':
                return styles.swimlaneItemBgBlueToGreen;
            default:
                return '';
        }
    };

    const handleProgressAppointmentStatus = async () => {
        if (!clinicId) return;
        const prevStatus = currentAppointmentStatus;
        const db = getFirestore();
        const docRef = doc(
            collection(db, 'clinicSettings', clinicId, 'appointments'),
            appointment.id
        );
        if (currentAppointmentStatus === 'checked in') {
            updateDoc(docRef, {
                status: 'in treatment',
                updatedAt: new Timestamp(Date.now() / 1000, 0),
            });
            setCurrentAppointmentStatus('in treatment');
        }
        if (currentAppointmentStatus === 'in treatment') {
            updateDoc(docRef, {
                status: 'treated',
                updatedAt: new Timestamp(Date.now() / 1000, 0),
            });

            setCurrentAppointmentStatus('treated');
        }
        if (currentAppointmentStatus === 'treated') {
            updateDoc(docRef, { status: 'closed', updatedAt: new Timestamp(Date.now() / 1000, 0) });
            setCurrentAppointmentStatus('closed');
        }
        logEvent(analytics, 'appointment_status_updated', {
            user: authUser?.email,
            patient: appointment.patient.socialSec,
            status: currentAppointmentStatus,
            prevStatus,
        });
    };

    const handleUndoAppointmentStatus = () => {
        if (!clinicId) return;
        const prevStatus = currentAppointmentStatus;
        const db = getFirestore();
        const colRef = collection(db, 'clinicSettings', clinicId, 'appointments');
        const docRef = doc(colRef, appointment.id);
        if (currentAppointmentStatus === 'in treatment') {
            updateDoc(docRef, { status: 'checked in' });
        }
        if (currentAppointmentStatus === 'treated') {
            updateDoc(docRef, { status: 'in treatment' });
        }
        logEvent(analytics, 'appointment_status_updated', {
            user: authUser?.email,
            patient: appointment.patient.socialSec,
            status: currentAppointmentStatus,
            prevStatus,
        });
    };
    const copyDataToClipBoard = () => {
        let data = '';
        if (appointment.patient) {
            for (const property in appointment.patient) {
                if (property === 'SocialSec') {
                    const secNumber = formatSecurityNumber(appointment.patient[property]);
                    data += `${secNumber} `;
                }
                data += `${appointment.patient[property]} `;
            }
        }
        if (appointment.screening) {
            for (const property in appointment.screening) {
                data += `${appointment.screening[property]} `;
            }
        }
        navigator.clipboard.writeText(data);
        if (handleCopyPopup) {
            handleCopyPopup();
        }
    };

    return (
        <div className={`${styles.swimlaneItem} ${getAppointmentStatusStyles(appointment.status)}`}>
            <div className={styles.swimlaneItemTopRow}>
                <div className={styles.nameContainer}>
                    <p className={styles.swimlaneItemName}>
                        {formatSecurityNumber(appointment.patient.socialSec)}
                    </p>
                    <p className={styles.swimlaneItemName}>
                        {appointment.patient && appointment.patient.firstName
                            ? `${appointment.patient.firstName} ${appointment.patient.lastName}`
                            : ''}
                    </p>
                </div>
                <div className={styles.copyLogo_crossLogo_container}>
                    <div onClick={copyDataToClipBoard} className={styles.copyLogoContainer}>
                        <img
                            src={
                                currentAppointmentStatus === 'checked in' ? CopyIconDark : CopyIcon
                            }
                            alt="Copy"
                            className={styles.copyLogo}
                        />
                    </div>
                    <div className={styles.swimlaneItemButtonContainer}>
                        {appointment.status !== 'checked in' && (
                            <button
                                className={styles.swimlaneItemButton}
                                onMouseUp={async (e) => {
                                    e.stopPropagation();
                                    handleUndoAppointmentStatus();
                                }}
                            >
                                {t('undo')}
                            </button>
                        )}
                        {currentAppointmentStatus !== 'treated' && (
                            <button
                                className={styles.swimlaneItemButton}
                                onMouseUp={(e) => {
                                    e.stopPropagation();
                                    setAppointmentsToClose([appointment]);
                                    setIsRemoveModalOpen(true);
                                }}
                            >
                                <img
                                    src={
                                        currentAppointmentStatus === 'checked in'
                                            ? CROSS_ICON_DARK
                                            : CROSS_ICON_WHITE
                                    }
                                    alt=""
                                />
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {settings?.config.screening && (
                <SwimlaneItemAppointmentDataRow
                    survey={appointment.screening}
                    currentAppointmentStatus={currentAppointmentStatus}
                    handleCopyPopup={handleCopyPopup}
                />
            )}
            <p className={styles.swimlaneItemTime}>{timeSinceUpdated}</p>
            <div
                className={`${styles.swimlaneItemAppointmentStatus} ${
                    currentAppointmentStatus === 'checked in'
                        ? styles.swimlaneItemAppointmentStatusGreenToBlue
                        : styles.swimlaneItemAppointmentStatusWhiteAndGreen
                }`}
                onMouseUp={async (e) => {
                    e.stopPropagation();
                    await handleProgressAppointmentStatus();
                }}
            >
                <p>{t(currentAppointmentStatus.replaceAll(' ', '_'))}</p>
            </div>
        </div>
    );
};
export default SwimlaneItem;
