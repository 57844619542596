import React, { FC, useEffect } from 'react';
import Login from 'pages/Login';
import Home from 'pages/Home';
import { useAuth } from 'hooks/useAuth';
import { collection, doc, getDoc, getFirestore, onSnapshot } from 'firebase/firestore';
import { useSettings } from 'hooks/SettingsContext';
import { Clinic, Settings } from 'types';
import Loading from 'pages/Loading';
import { useClinic } from 'hooks/ClinicContext';
import { Route, Routes } from 'react-router-dom';
import NotFound from 'pages/NotFound';

const App: FC = () => {
    const { authUser, clinicId, isLoading } = useAuth();
    const { setSettings } = useSettings();
    const { setSelectedClinic, setClinicsInOrganization } = useClinic();

    useEffect(() => {
        if (!authUser || !clinicId) return undefined;
        const db = getFirestore();
        const docRef = doc(db, 'clinicSettings', clinicId);
        const unSubSettings = onSnapshot(docRef, (snapshotSettings) => {
            const settingsData = snapshotSettings.data() as Settings;
            setSettings(settingsData);
        });
        return () => unSubSettings();
    }, [authUser, clinicId, setSettings]);

    useEffect(() => {
        if (!authUser || !clinicId) return undefined;
        const db = getFirestore();
        const colRef = collection(db, 'clinics');
        const docRef = doc(colRef, clinicId);

        const unSubClinics = onSnapshot(colRef, async (snapshotClinic) => {
            const docSnapShot = await getDoc(docRef);
            const clinic = docSnapShot.data() as Clinic;
            const clinics = snapshotClinic.docs
                .map((clinic) => {
                    return { id: clinic.id, ...clinic.data() } as Clinic;
                })
                .filter((elem) => clinic.clinicsInOrganization.includes(elem.id));

            setClinicsInOrganization(clinics);
            const currentClinic = clinics.find((clinic) => clinic.id === clinicId);
            if (!currentClinic) return;
            setSelectedClinic(currentClinic);
        });
        return () => unSubClinics();
    }, [authUser, clinicId, setSelectedClinic, setClinicsInOrganization]);

    return !isLoading ? (
        <Routes>
            {clinicId && authUser ? (
                <>
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<NotFound />} />
                </>
            ) : (
                <>
                    <Route path="*" element={<Login />} />
                </>
            )}
        </Routes>
    ) : (
        <Loading />
    );
};

export default App;
