import React, {
    createContext,
    useContext,
    useState,
    FC,
    ReactElement,
    SetStateAction,
    Dispatch,
} from 'react';
import { SurveyQuestion } from 'types';

export type SurveyContextType = {
    surveyQuestions: SurveyQuestion[] | undefined;
    setSurveyQuestions: Dispatch<SetStateAction<SurveyQuestion[] | undefined>>;
};

const SurveyContext = createContext<SurveyContextType>({
    surveyQuestions: undefined,
    setSurveyQuestions: () => undefined,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSurvey = () => useContext(SurveyContext);

type SurveyProviderProps = {
    children: ReactElement | ReactElement[];
};

const SurveyProvider: FC<SurveyProviderProps> = ({ children }) => {
    const [surveyQuestions, setSurveyQuestions] = useState<SurveyQuestion[]>();

    return (
        <SurveyContext.Provider
            value={{
                surveyQuestions,
                setSurveyQuestions,
            }}
        >
            {children}
        </SurveyContext.Provider>
    );
};

export { SurveyProvider, useSurvey };
