import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './home.module.scss';
import { useClinic } from 'hooks/ClinicContext';
import { Appointment } from 'types';
import { useTranslation } from 'react-i18next';
import SwimlaneItemList from 'components/SwimlaneItemList';
import { useRemoveModal } from 'hooks/RemoveModalContext';
import RemoveModal from 'components/RemoveModal';
import Header from 'components/Header';
import { collection, getFirestore, onSnapshot, query, where } from 'firebase/firestore';
import { useSettings } from 'hooks/SettingsContext';
import { decrypt } from '../../crypto';
import { useAuth } from 'hooks/useAuth';

const Home: FC = () => {
    const [appointments, setAppointments] = useState<Appointment[]>();
    const [showPopup, setShowPopup] = useState(false);
    const { isRemoveModalOpen, setIsRemoveModalOpen, setAppointmentsToClose } = useRemoveModal();
    const { t } = useTranslation();
    const { selectedClinic } = useClinic();
    const { clinicId } = useAuth();

    const { settings } = useSettings();
    useEffect(() => {
        if (!selectedClinic) return undefined;

        const notify = async (socialSec: string) => {
            const notificationBody = {
                icon: settings?.notificationIcon,
            };
            if (!('Notification' in window)) {
                return;
            } else if (Notification.permission === 'granted') {
                // eslint-disable-next-line no-new
                new Notification(`${t('notification_title')} ${socialSec}`, notificationBody);
            } else if (Notification.permission !== 'denied') {
                const permission = await Notification.requestPermission().catch((err) => err);
                if (permission === 'granted') {
                    // eslint-disable-next-line no-new
                    new Notification(`${t('notification_title')} ${socialSec}`, notificationBody);
                }
            }
        };

        const db = getFirestore();
        const colRef = collection(db, 'clinicSettings', selectedClinic.id, 'appointments');
        const qAppointments = query(
            colRef,
            where('clinic', 'in', selectedClinic?.clinicsInOrganization),
            where('status', '!=', 'closed')
        );
        const unSubAppointments = onSnapshot(qAppointments, async (snapshotAppointments) => {
            const appointmentData = snapshotAppointments.docs
                .map((appointmentRef) => {
                    return { id: appointmentRef.id, ...appointmentRef.data() } as Appointment;
                })
                .sort((a, b) => {
                    return a.updatedAt.seconds < b.updatedAt.seconds ? 1 : -1;
                });

            const latestAppointment = appointmentData[0];
            if (
                latestAppointment &&
                Date.now() / 1000 - latestAppointment.updatedAt.seconds <= 0.8 &&
                latestAppointment.status === 'checked in'
            ) {
                const socialSec = decrypt(latestAppointment.patient.socialSec);
                if (!socialSec) return;
                await notify(socialSec);
            }

            setAppointments(appointmentData);
        });
        return () => unSubAppointments();
    }, [selectedClinic, settings?.notificationIcon, t]);

    const clinicStats = useMemo(() => {
        const amountPatientsInList = (appointmentStatus: string) => {
            return appointments
                ?.map((appointment) => {
                    return {
                        status: appointment.status,
                        clinic: appointment.clinic,
                    };
                })
                .filter(
                    (appointment) =>
                        appointment.clinic === selectedClinic?.id &&
                        appointment.status === appointmentStatus
                ).length;
        };
        const amountCheckedIn = amountPatientsInList('checked in');
        const amountInTreatment = amountPatientsInList('in treatment');
        const amountTreated = amountPatientsInList('treated');
        return { amountCheckedIn, amountInTreatment, amountTreated };
    }, [appointments, selectedClinic]);

    const handleCopyPopup = () => {
        setShowPopup(!showPopup);
        setTimeout(() => {
            setShowPopup(false);
        }, 1000);
    };

    return (
        <>
            <Header />
            <RemoveModal clinicId={clinicId as string} />
            <div className={`${styles.wrapper} ${isRemoveModalOpen ? styles.blur : ''}`}>
                <div className={`${styles.wrapper}`}>
                    <div className={styles.innerWrapper}>
                        <h2 className={styles.innerWrapperTitle}>{t('checked_in_patients')}</h2>
                        <div className={styles.innerWrapperSwimlanesContainer}>
                            <div className={styles.swimlaneContainer}>
                                <h3>{`${t('in_waitingroom')}: ${
                                    clinicStats.amountCheckedIn ?? 0
                                }`}</h3>
                                <div className={styles.swimlane}>
                                    <SwimlaneItemList
                                        handleCopyPopup={handleCopyPopup}
                                        appointments={appointments?.filter(
                                            (appointment) =>
                                                appointment.status === 'checked in' &&
                                                appointment.clinic === selectedClinic?.id
                                        )}
                                    />
                                </div>
                            </div>
                            <div className={styles.swimlaneContainer}>
                                <div className={styles.swimlaneContainerHeader}>
                                    <h3>{t('patients')}</h3>
                                    <button
                                        onClick={() => {
                                            const appointmentsToClose = appointments?.filter(
                                                (appointment) => appointment.status === 'treated'
                                            );
                                            if (!appointmentsToClose) return;
                                            setAppointmentsToClose(appointmentsToClose);
                                            setIsRemoveModalOpen(true);
                                        }}
                                        disabled={clinicStats.amountTreated === 0}
                                    >
                                        {t('remove_all')}
                                    </button>
                                </div>
                                <div className={styles.swimlane}>
                                    <h4>{`${t('in_treatment')}: ${
                                        clinicStats.amountInTreatment ?? 0
                                    }`}</h4>
                                    <SwimlaneItemList
                                        handleCopyPopup={handleCopyPopup}
                                        appointments={appointments?.filter(
                                            (appointment) =>
                                                appointment.status === 'in treatment' &&
                                                appointment.clinic === selectedClinic?.id
                                        )}
                                    />
                                    <h4 style={{ marginTop: '4rem' }}>{`${t('treated_today')}: ${
                                        clinicStats.amountTreated ?? 0
                                    }`}</h4>
                                    <SwimlaneItemList
                                        appointments={appointments?.filter(
                                            (appointment) =>
                                                appointment.status === 'treated' &&
                                                appointment.clinic === selectedClinic?.id
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showPopup ? <span className={styles.popup}>Copied to clipboard</span> : null}
            </div>
        </>
    );
};

export default Home;
