import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signInWithEmailAndPassword,
    UserCredential,
    browserSessionPersistence,
    setPersistence,
    signOut,
} from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import moment from 'moment';
import 'moment/locale/sv';

moment.locale('se');

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const organization = 'carelabs.se';

const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);

export const analytics = getAnalytics(app);

export const signInWithEmailPassword = async (
    email: string,
    password: string
): Promise<UserCredential> => {
    await setPersistence(authentication, browserSessionPersistence);
    return signInWithEmailAndPassword(authentication, email, password);
};
export const signOutUser = (): Promise<void> => {
    return signOut(authentication);
};
