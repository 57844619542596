import React, { createContext, useContext, useState, FC, ReactElement } from 'react';
import { Appointment } from 'types';

type RemoveModal = {
    isRemoveModalOpen: boolean;
    setIsRemoveModalOpen: (isOpen: boolean) => void;
    appointmentsToClose: Appointment[] | null;
    setAppointmentsToClose: (appointments: Appointment[] | null) => void;
};

const RemoveModalContext = createContext<RemoveModal>({
    isRemoveModalOpen: false,
    setIsRemoveModalOpen: () => null,
    appointmentsToClose: null,
    setAppointmentsToClose: () => null,
});
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useRemoveModal = () => useContext(RemoveModalContext);

type RemoveModalProviderProps = {
    children: ReactElement | ReactElement[];
};

const RemoveModalProvider: FC<RemoveModalProviderProps> = ({ children }) => {
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const [appointmentsToClose, setAppointmentsToClose] = useState<Appointment[] | null>(null);

    return (
        <RemoveModalContext.Provider
            value={{
                isRemoveModalOpen,
                setIsRemoveModalOpen,
                appointmentsToClose,
                setAppointmentsToClose,
            }}
        >
            {children}
        </RemoveModalContext.Provider>
    );
};

export { RemoveModalProvider, useRemoveModal };
