/* eslint-disable indent */
import React, { FC, useEffect, useRef } from 'react';
import { useRemoveModal } from 'hooks/RemoveModalContext';
import styles from './removeModal.module.scss';
import { useTranslation } from 'react-i18next';
import { collection, doc, getFirestore, updateDoc, writeBatch } from 'firebase/firestore';
import { Appointment } from 'types';
import { decrypt } from '../../crypto';

type Props = {
    clinicId: string;
};

const RemoveModal: FC<Props> = ({ clinicId }) => {
    const { isRemoveModalOpen, setIsRemoveModalOpen, appointmentsToClose } = useRemoveModal();
    const removeModalRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (removeModalRef.current && !removeModalRef.current.contains(e.target as Node)) {
                setIsRemoveModalOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeAppointments = async (appointments: Appointment[]) => {
        if (!appointments && !clinicId) return;
        const db = getFirestore();
        const colRef = collection(db, 'clinicSettings', clinicId, 'appointments');
        if (appointments.length > 1) {
            const batch = writeBatch(db);
            appointments.forEach((appointment) => {
                const docRef = doc(colRef, appointment.id);
                batch.update(docRef, { status: 'closed' });
            });
            batch.commit();
        } else {
            const docRef = doc(colRef, appointments[0].id);
            updateDoc(docRef, { status: 'closed' });
        }
    };

    return (
        <>
            {isRemoveModalOpen && (
                <div ref={removeModalRef} className={styles.wrapper}>
                    {appointmentsToClose && appointmentsToClose?.length > 1
                        ? t('remove_modal_info_text_remove_all')
                        : `${t('remove_modal_info_text_remove_by_id', {
                              securityNumber:
                                  appointmentsToClose &&
                                  decrypt(appointmentsToClose[0].patient?.socialSec),
                          })}`}
                    <div className={styles.removeModalButtonContainer}>
                        <button
                            onClick={() => {
                                setIsRemoveModalOpen(false);
                            }}
                            className={`${styles.removeModalButton} ${styles.removeModalButtonDismiss}`}
                        >
                            {t('remove_modal_dismiss_button_text')}
                        </button>
                        <button
                            onClick={async () => {
                                if (!appointmentsToClose) return;
                                closeAppointments(appointmentsToClose);
                                setIsRemoveModalOpen(false);
                            }}
                            className={`${styles.removeModalButton} ${styles.removeModalButtonApprove}`}
                        >
                            {t('remove_modal_approve_button_text')}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default RemoveModal;
